import axios from 'axios';
import { queryParamsBuilder } from 'core/helpers/queryParamsHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { IMe } from 'core/interfaces/account';
import { IAccountsInventoryOptions } from 'core/interfaces/accountApi';
//import type { paths, components } from 'core/types/schema'; // generated by openapi-typescript

const changePassword = async (email: string) =>
  axios({
    method: 'post',
    url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      email,
      connection: 'Username-Password-Authentication',
    },
  });

//type myType = components['schemas']['AccountingDto'];

const getAccount = () => axiosInstance.get(`/me`);

export const getAccountProfile = () => axiosInstance.get(`/me/profile`);

const patchAccount = async ({ language, phoneValue, notifications, picture }: Partial<IMe>) =>
  axiosInstance.patch(`/me`, {
    language,
    phoneValue,
    notifications,
    picture,
  });

export const patchAccountPartial = (payload: Partial<IMe>) =>
  axiosInstance.patch(`/me`, {
    ...payload,
  });

const getAccounts = (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);
  return axiosInstance.get(`/accounts${queryParams}`);
};

const getFavoriteAccounts = (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);
  return axiosInstance.get(`/accounts/favorites${queryParams}`);
};

const getAccountsListDetail = (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);

  return axiosInstance.get(`/accounts/list/detail/${queryParams}`);
};

const getAccountById = (accountId: string) => {
  return axiosInstance.get(`/account/${accountId}`);
};

const fetchCommonGroups = (accountIds: string[], groupId: string | null = null) => {
  return axiosInstance.post(`/groups/shared`, { accountIds, groupId });
};

const getAccountsTotalByCompany = (idCompany: number) => {
  return axiosInstance.get(`/company/${idCompany}/count/accounts`);
};

export {
  patchAccount,
  getAccountById,
  getAccountsListDetail,
  changePassword,
  getAccount,
  getAccounts,
  getFavoriteAccounts,
  fetchCommonGroups,
  getAccountsTotalByCompany,
};
