import { IconCliveLogo, NavBar } from '@bringyourculturelive/design-system';
import { AvatarProps, ButtonProps, Menu, MenuProps, PopoverProps, theme } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from 'state/accountSlice';
import { t } from 'i18next';
import { LogoutOutlined, /*UserSwitchOutlined,*/ UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Screens } from 'core/routes/Screens';
import { useEffectOnce, useToggle } from 'react-use';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { ImageFullHeightCover } from './style';

type MenuItem = Required<MenuProps>['items'][number];

export const AntHeader = memo(() => {
  const { useToken } = theme;

  const NavBarRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { sizeMS } = useToken().token;

  const { account } = useSelector(selectAccount);

  const { picture } = account;

  const { logout } = useAuth0();

  const redirectTo: LogoutOptions = { logoutParams: { returnTo: window.location.origin } };

  const [displayPopOverContent, togglePopOver] = useToggle(false);

  const items: ItemType[] = [
    {
      label: <>{t('V3_ASIDE_MENU.LABEL_HOME')}</>,
      key: 'wall',
      onClick: () => navigate('/'),
    },
    {
      label: <>{t('V3_ASIDE_MENU.LABEL_SPENDING_TOKENS')}</>,
      key: 'rewards',
      onClick: () =>
        navigate(`/v4/${Screens.Rewards.path}/${Screens.Rewards.RewardsCollection.path}`),
    },
  ];

  const profileProps: AvatarProps = {
    icon: (
      <ImageFullHeightCover
        preview={false}
        src={picture || undefined}
        wrapperStyle={{ height: '100%' }}
      />
    ),
    style: {
      cursor: 'pointer',
    },
  };

  const logoProps: ButtonProps = {
    icon: <IconCliveLogo style={{ width: 35 }} />,
    style: { marginInlineEnd: sizeMS },
    type: 'link',
    onClick: () => navigate('/'),
  };

  const menuItems: MenuItem[] = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: String(t('EDIT_PROFILE.TITLE')),
      onClick: () => {
        navigate(`/v4/${Screens.ProfileV4.path}/${Screens.ProfileV4.ProfileEdit.path}`);
        togglePopOver(false);
      },
    },
    /*{
      key: '2',
      icon: <UserSwitchOutlined />,
      label: String(t('EDIT_PROFILE.SWITCH')),
      onClick: () => navigate(`/${Screens.Profile.path}`),
    },*/
    {
      key: '3',
      icon: <LogoutOutlined />,
      label: String(t('PROFILE.LOGOUT')),
      onClick: () => logout(redirectTo),
    },
  ];

  const content = <Menu items={menuItems} style={{ border: 0 }} />;

  const handleOpenChange = (newOpen: boolean) => {
    togglePopOver(newOpen);
  };

  const profilePopoverProps: PopoverProps = {
    content,
    trigger: 'click',
    placement: 'leftTop',
    onOpenChange: handleOpenChange,
    open: displayPopOverContent,
  };

  useEffectOnce(() => {
    const headerStyle = NavBarRef.current?.getAttribute('style');
    NavBarRef.current?.setAttribute('style', `${headerStyle} padding-inline:0;`);
  });

  return (
    <NavBar
      ref={NavBarRef}
      items={items}
      {...{ profileProps }}
      {...{ logoProps }}
      {...{ profilePopoverProps }}
    />
  );
});

AntHeader.displayName = 'Components_AntHeader';
