import { lazy } from 'react';

export const Screens = {
  //START v4
  Rewards: {
    path: 'rewards',
    Component: lazy(() => import('pages/v4/Rewards')),
    RewardsCollection: {
      path: 'collection',
      Component: lazy(() => import('pages/v4/Rewards/Collection')),
    },
    RewardsHappening: {
      path: 'happening',
      Component: lazy(() => import('pages/v4/Rewards/Happening')),
    },
    HappeningsClosed: {
      path: 'happening/closed',
      Component: lazy(() => import('pages/v4/Rewards/Happening/All')),
    },
    RewardsMarketPlace: {
      path: 'marketplace',
      Component: lazy(() => import('pages/v4/Rewards/MarketPlace')),
    },
    BrandsAll: {
      path: 'marketplace/gift-card',
      Component: lazy(() => import('pages/v4/Rewards/Brands')),
    },
    CharitiesAll: {
      path: 'marketplace/charities',
      Component: lazy(() => import('pages/v4/Rewards/Charities')),
    },

    CompanyAdvantagesAll: {
      path: 'marketplace/company-advantages',
      Component: lazy(() => import('pages/v4/Rewards/Advantages')),
    },
    CollectionDetails: {
      path: 'collection/detail/:id',
      Component: lazy(() => import('pages/v4/Rewards/Collection/Details')),
    },
    RewardsBrandsId: {
      path: 'marketplace/gift-card/detail/:id',
      Component: lazy(() => import('pages/v4/Rewards/BrandDetails')),
    },
    CompanyAdvantagesDetails: {
      path: 'marketplace/company-advantages/detail/:id',
      Component: lazy(() => import('pages/v4/Rewards/Advantages/Details')),
    },
    HappeningsOnProgress: {
      path: 'happening/in-progress',
      Component: lazy(() => import('pages/v4/Rewards/Happening/All')),
    },
  },
  Contact: {
    Inactive: {
      path: 'inactive',
      Component: lazy(() => import('pages/v4/Contact/Inactive')),
    },
    Revoked: {
      path: 'revoked',
      Component: lazy(() => import('pages/v4/Contact/Revoked')),
    },
    UnknownCompany: {
      path: 'unknown',
      Component: lazy(() => import('pages/v4/Contact/UnknownCompany')),
    },
  },
  Errors: {
    UnauthorizedV4: {
      path: 'unauthorized',
      Component: lazy(() => import('pages/v4/Errors/Unauthorized')),
    },
    NotFoundV4: {
      path: 'notfound',
      Component: lazy(() => import('pages/v4/Errors/NotFound')),
    },
    ServerErrorV4: {
      path: 'servererror',
      Component: lazy(() => import('pages/v4/Errors/ServorError')),
    },
    MaintenanceV4: {
      path: 'maintenance',
      Component: lazy(() => import('pages/v4/Errors/Maintenance')),
    },
    ErrorV4: {
      path: 'error',
      Component: lazy(() => import('pages/v4/Errors/Error')),
    },
  },
  ProfileV4: {
    path: 'profile',
    Component: lazy(() => import('pages/v4/Profile')),
    ProfileSupport: {
      path: 'support',
      Component: lazy(() => import('pages/v4/Profile/Support')),
    },
    ProfileEdit: {
      path: 'edit',
      Component: lazy(() => import('pages/v4/Profile/Edit')),
    },
  },
  //END v4
  Unity: {
    path: '/unity',
    Component: lazy(() => import('pages/Unity')),
  },
  Home: {
    path: '/',
    Component: lazy(() => import('pages/v3/Home')),
  },
  MyCompany: {
    path: '/mycompany',
    Component: lazy(() => import('pages/v3/MyCompany')),
  },
  MyCompanyHappening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/MyCompany/Happening')),
  },
  MyCompanyScoreBoard: {
    path: 'scoreboard',
    Component: lazy(() => import('pages/v3/MyCompany/ScoreBoard')),
  },
  PostIdDetails: {
    path: '/post/:id/details',
    Component: lazy(() => import('features/PostCardList/PostDetails')),
  },
  PostIdDetailsStandAlone: {
    path: '/post/:id/details-standalone',
    Component: lazy(() => import('features/PostCardList/PostDetailsStandAlone')),
  },
  SpendingTokens: {
    path: '/spending-tokens',
    Component: lazy(() => import('pages/v3/SpendToken')),
  },
  MyCollection: {
    path: 'my-collection',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection')),
  },
  MyCollectionTokenId: {
    path: 'token/:id',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection/PopUpTokenCollection')),
  },
  Marketplace: {
    path: 'marketplace',
    Component: lazy(() => import('pages/v3/SpendToken/marketPlace')),
  },
  Happening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/SpendToken/happening')),
  },
  HappeningInProgress: {
    path: '/happening-in-progress',
    Component: lazy(
      () => import('pages/v3/SpendToken/happening/HappeningInprogress/HappeningInprogress')
    ),
  },
  HappeningClosed: {
    path: '/happening-closed',
    Component: lazy(() => import('pages/v3/SpendToken/happening/HappeningClosed/HappeningClosed')),
  },
  Profile: {
    path: 'profile',
    Component: lazy(() => import('pages/profile/Profile')),
  },
  ProfileEdit: {
    path: '/profile/edit',
    Component: lazy(() => import('pages/profile/editProfile/EditProfile')),
  },
  ProfileNotifications: {
    path: '/profile/notifications',
    Component: lazy(() => import('pages/profile/manageNotifications/ManageNotifications')),
  },
  ProfilePassword: {
    path: '/profile/password',
    Component: lazy(() => import('pages/profile/editPassword/EditPassword')),
  },
  ProfileLanguage: {
    path: '/profile/language',
    Component: lazy(() => import('pages/profile/editLanguage/EditLanguage')),
  },
  Suggestions: {
    path: '/suggestions',
    Component: lazy(() => import('pages/impactCommunity/helpImprovement/Suggestions')),
  },
  PeerPecognition: {
    path: '/peer-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  MarketplaceBrandsId: {
    path: '/marketplace/brands/:id',
    Component: lazy(() => import('pages/marketplace/brands/productDetails/ProductDetails')),
  },
  MarketplaceBrands: {
    path: '/marketplace/brands',
    Component: lazy(() => import('pages/marketplace/brands/productList/ProductList')),
  },
  MarketplacePurchaseApproveId: {
    path: '/marketplace/purchase/approve/:productOrderCompanyId/:managerAccountId',
  },
  Charities: {
    path: '/charities',
    Component: lazy(() => import('pages/charities/Charities')),
  },
  CompanyAdvantages: {
    path: '/company-advantages',
    Component: lazy(() => import('pages/prosumer/companyAdvantages/CompanyAdvantages')),
  },
  CompanyAdvantagesId: {
    path: '/company-advantages/:id',
    Component: lazy(() => import('pages/prosumer/productProsumerDetails/ProductProsumerDetails')),
  },
  NotFound: {
    path: '/notFound',
    Component: lazy(() => import('pages/errorPages/NotFound')),
  },
  Denied: {
    path: '/denied',
    Component: lazy(() => import('pages/errorPages/ErrorDeniedPage')),
  },
  Revoked: {
    path: '/revoked',
    Component: lazy(() => import('pages/errorPages/ErrorRevoked')),
  },
  ErrorCompanyNotKnown: {
    path: '/error-company-not-known',
    Component: lazy(() => import('pages/errorPages/ErrorCompanyNotKnown')),
  },
};

export const ScreensTeams = {
  TeamsWall: {
    path: '/teams-wall',
    Component: lazy(() => import('pages/v3/Home')),
  },
  TeamsSpending: {
    path: '/teams-spending',
    Component: lazy(() => import('pages/v3/SpendToken')),
  },
  Unity: {
    path: '/unity',
    Component: lazy(() => import('pages/Unity')),
  },
  MyCollection: {
    path: 'my-collection',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection')),
  },
  MyCollectionTokenId: {
    path: 'token/:id',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection/PopUpTokenCollection')),
  },
  Marketplace: {
    path: 'marketplace',
    Component: lazy(() => import('pages/v3/SpendToken/marketPlace')),
  },
  Happening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/SpendToken/happening')),
  },
  HappeningInProgress: {
    path: 'happening-in-progress',
    Component: lazy(
      () => import('pages/v3/SpendToken/happening/HappeningInprogress/HappeningInprogress')
    ),
  },
  HappeningClosed: {
    path: 'happening-closed',
    Component: lazy(() => import('pages/v3/SpendToken/happening/HappeningClosed/HappeningClosed')),
  },
  PeerPecognition: {
    path: '/peer-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  TeamsRecognition: {
    path: '/teams-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  Charities: {
    path: '/charities',
    Component: lazy(() => import('pages/charities/Charities')),
  },
  CompanyAdvantages: {
    path: '/company-advantages',
    Component: lazy(() => import('pages/prosumer/companyAdvantages/CompanyAdvantages')),
  },
  CompanyAdvantagesId: {
    path: '/company-advantages/:id',
    Component: lazy(() => import('pages/prosumer/productProsumerDetails/ProductProsumerDetails')),
  },
  MarketplaceBrands: {
    path: '/marketplace/brands',
    Component: lazy(() => import('pages/marketplace/brands/productList/ProductList')),
  },
  MarketplaceBrandsId: {
    path: '/marketplace/brands/:id',
    Component: lazy(() => import('pages/marketplace/brands/productDetails/ProductDetails')),
  },
  PostIdDetails: {
    path: '/post/:id/details',
    Component: lazy(() => import('features/PostCardList/PostDetails')),
  },
  PostIdDetailsStandAlone: {
    path: '/post/:id/details-standalone',
    Component: lazy(() => import('features/PostCardList/PostDetailsStandAlone')),
  },
};
